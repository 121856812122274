import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { AspectRatioFence } from 'react-aspect-ratio-fence';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from './course-info-card.module.scss';
config.autoAddCss = false;

const getFontSize = (breakpoints) => {
  if (breakpoints.mobile) { return { courseName: 20, scholarity: 11 }; }
  if (breakpoints.tablet) { return { courseName: 30, scholarity: 13 }; }
  if (breakpoints.laptop) { return { courseName: 30, scholarity: 13 }; }
  return { courseName: 40, scholarity: 15 };
};

const getMargin = (breakpoints) => {
  if (breakpoints.mobile) { return 40; }
  if (breakpoints.tablet) { return 30; }
  if (breakpoints.laptop) { return 30; }
  return 40;
};

const renderTitle = (courseName, fontSize, isMobile) => {
  if (courseName) {
    return (
      <h2
      style={{
        fontSize: fontSize.courseName,
        margin: 0,
        color: 'white',
        cursor: isMobile ? 'pointer' : 'unset',
        marginBottom: isMobile ? 8 : 12,
      }}
      className={styles.courseName}
    >
      {courseName}
    </h2>  
    );
  }
  return null;
};

const renderSubtitle = (scholarity, fontSize) => {
  if (scholarity) {
    return (
      <p
        style={{ color: 'white', fontSize: fontSize.scholarity }}
        className={styles.scholarity}
      >
        {scholarity}
      </p>  
    );
  }
  return null;
};

const humanizeEndDate = endDate => {
  if (endDate) {
    return (
      <div className={styles.closingDate}>
        <FontAwesomeIcon color="#ffffff" icon={faClock} size="1x" />
        <p>{endDate}</p>
      </div>
    );
  }
  return null;
};

const actionButton = path => {
  if (path) {
    return (
      <Link to={`${path}/`} className={styles.linkToCourse} style={{ width: 160, height: 39 }}>
        Saber mais
      </Link>
    );
  }
  return null;
};

const prepareBackgroundImage = (receivedImahe, placeholderImage) => {
  // in case the image is not passed, a placehlder image is used
  const currentImage = receivedImahe ? receivedImahe : placeholderImage;
  return [currentImage, `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`].reverse();
};

const InfoCourseCardFluid = (props) => {
  const breakpoints = useBreakpoint();

  const data = useStaticQuery(graphql`
    query CourseFluidPlaceholdeImageQuery {
      placeholder: file(name: {eq: "placeholder-course"}) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const styleConfig = breakpoints.mobile ? props.mobile : props.desktop;
  const handleClick = () => { breakpoints.mobile && props.onClick(); };
  const fontSize = getFontSize(breakpoints);
  const margin = getMargin(breakpoints);
  const backgroundFluidImage = prepareBackgroundImage(props.fluid, data.placeholder.childImageSharp.fluid);

  return (
    <div onClick={handleClick} style={{ maxWidth: styleConfig.maxWidth }}>
      <AspectRatioFence elementType="div" ratio={1.22} className="stories_wrap_1x0ty">
        <div style={{ width: '100%', height: '100%' }}>
          <BackgroundImage style={{ width: '100%', height: '100%' }} fluid={backgroundFluidImage}>
            <div
              className={styles.container}
              style={{ padding: margin, height: `calc(100% - ${2 * margin}px)` }}
            >
              <div>
                {humanizeEndDate(props.endDate)}
              </div>
              <div className={styles.main}>
                {renderTitle(props.courseName, fontSize, breakpoints.mobile)}
                {renderSubtitle(props.scholarity, fontSize)}
                <div style={{ marginTop: 32 }}>
                  {actionButton(props.path)}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </AspectRatioFence>
    </div>
  );
};

export default InfoCourseCardFluid;

InfoCourseCardFluid.defaultProps = {
  onClick: () => {},
  desktop: {
    maxWidth: 255,
    fontSize: {
      courseName: 20,
      scholarity: 12,
    },
    margin: 40,
  },
  mobile: {
    maxWidth: 255,
    fontSize: {
      courseName: 20,
      scholarity: 12,
    },
    margin: 40,
  },
};

InfoCourseCardFluid.propTypes = {
  onClick: PropTypes.func,
  courseName: PropTypes.string,
  scholarity: PropTypes.string,
  path: PropTypes.string,
  endDate: PropTypes.string,
  fluid: PropTypes.object,
  desktop: PropTypes.shape({
    maxWidth: PropTypes.number,
    fontSize: PropTypes.shape({
      courseName: PropTypes.number,
      scholarity: PropTypes.number,
    }),
    margin: PropTypes.number,
  }),
  mobile: PropTypes.shape({
    maxWidth: PropTypes.number,
    fontSize: PropTypes.shape({
      courseName: PropTypes.number,
      scholarity: PropTypes.number,
    }),
    margin: PropTypes.number,
  }),
};

