import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import styles from './course-info-card.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import BackgroundImage from 'gatsby-background-image';



const InfoCourseCard = (props) => {
  const breakpoints = useBreakpoint();

  const data = useStaticQuery(graphql`
    query CoursePlaceholdeImageQuery {
      placeholder: file(name: {eq: "placeholder-course"}) {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const styleConfig = breakpoints.mobile ? props.mobile : props.desktop;
  // in case the image is not passed, a placehlder image is used
  const currentImage = props.fluid ? props.fluid : data.placeholder.childImageSharp.fluid;

  const backgroundFluidImageStack = [
    currentImage,
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`
  ].reverse();

  return (
    <div onClick={props.onClick} style={{ cursor: 'pointer' }}>

      <BackgroundImage style={{ width: styleConfig.width, height: styleConfig.height }} fluid={backgroundFluidImageStack}>

        <div className={styles.container} style={{ padding: styleConfig.margin, height: `calc(100% - ${2 * styleConfig.margin}px)` }}>

          <div className={styles.main}>
            {/* bottom */}
            {
              props.courseName &&
              <h2
                style={{
                  fontSize: styleConfig.fontSize.courseName,
                  margin: 0,
                  color: 'white',
                  marginBottom: breakpoints.mobile ? 8 : 12
                }}
                className={styles.courseName}
              >
                {props.courseName}&nbsp;
              </h2>  
            }
            {
              props.scholarity &&
              <p style={{ color: 'white', fontSize: styleConfig.fontSize.scholarity }} className={styles.scholarity}>{props.scholarity}&nbsp;</p>  
            }
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};


export default InfoCourseCard;

InfoCourseCard.defaultProps = {
  onClick: () => {},
  desktop: {
    height: 255,
    width: 255,
    fontSize: {
      courseName: 20,
      scholarity: 12,
    },
    margin: 40,
  },
  mobile: {
    height: 255,
    width: 255,
    fontSize: {
      courseName: 20,
      scholarity: 12,
    },
    margin: 40,
  },
};

InfoCourseCard.propTypes = {
  onClick: PropTypes.func,
  courseName: PropTypes.string,
  scholarity: PropTypes.string,
  fluid: PropTypes.object,
  desktop: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    fontSize: PropTypes.shape({
      courseName: PropTypes.number,
      scholarity: PropTypes.number,
    }),
    margin: PropTypes.number,
  }),
  mobile: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    fontSize: PropTypes.shape({
      courseName: PropTypes.number,
      scholarity: PropTypes.number,
    }),
    margin: PropTypes.number,
  }),
};

