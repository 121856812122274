import React from 'react';
import PropTypes from 'prop-types';
import InfoCourseCard from '@components/ui/course-info-card-fixed';
import { isCourseClosedForSignUp, getImageNodeByName, getPathToCoursePage } from '@utilities/course-data-helpers';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { navigateTo } from "gatsby";
import styles from './closed-courses.module.scss';

const renderHeader = () => (
  <div className={styles.description}>
    <section className={styles.heading}>
      <h2>Cursos passados</h2>
    </section>
    <section className={styles.details}>
      <div>
        <p>
          Aqui poderás encontrar informação sobre alguns dos cursos
          After School que já realizámos.  
        </p>
      </div>
    </section>
  </div>
);

const ClosedCourses = (props) => {
  const breakpoints = useBreakpoint();
  const coursesWithClosedSignUp = props.allCoursesData.edges.filter(isCourseClosedForSignUp);

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${breakpoints.mobile ? 158 : 255}px, 1fr))`,
    gridColumnGap: '29px',
    gridRowGap: '29px',
    justifyItems: breakpoints.mobile ? 'start' : 'center'
  };

  return (
    <div className={styles.container}>
      {renderHeader()}

      <div style={{ ...containerStyle }}>
        {
          [...coursesWithClosedSignUp].map(course => {
            const { id, general, description } = course.node;
            const handleClick = () => navigateTo(getPathToCoursePage(course, props.pathsToCourses)); 

            return (
              <div key={id}>
                <InfoCourseCard
                  onClick={handleClick}
                  courseName={general.courseName}
                  scholarity={description.scholarity}
                  fluid={getImageNodeByName(general.thumbnail, props.images).childImageSharp.fluid}
                  desktop={{
                    height: 255,
                    width: 255,
                    fontSize: { courseName: 20, scholarity: 12 },
                    margin: 40,
                  }}
                  mobile={{
                    height: 158,
                    width: 158,
                    fontSize: { courseName: 14, scholarity: 10 },
                    margin: 10,
                  }}
                />
              </div>
            );
          })
        }
      </div>

    </div>
  );
};

export default ClosedCourses;

ClosedCourses.propTypes = {
  images: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }),
  allCoursesData: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }),
  pathsToCourses: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  })
};