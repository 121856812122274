import React from 'react';
import SEO from '@components/ui/seo';
import Title from '@components/ui/title';
import Layout from '@components/domain/shared/layout';
import Spacing from '@components/ui/spacing';
import Sponsors from '@components/ui/sponsors';
import EmptySpace from '@components/ui/empty-space';
import DesktopNewsletter from '@components/ui/desktop-newsletter';
import OpenCoursesSection from '@components/domain/courses/open-courses';
import ClosedCoursesSection from '@components/domain/courses/closed-courses';
import { useCoursesMetadata } from '@queries/use-courses-metadata';

const Courses = () => {
  const { courseThumbnails, allCoursesJson, pathsToCourses } = useCoursesMetadata();

  return (
    <Layout>
      <SEO title="Cursos" description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática." />

      <EmptySpace
        desktop={{ margin: 200, isLineVisible: false }}
        mobile={{ margin: 120, isLineVisible: false }}
      />

      <Spacing>
        <Title
          value="Começa já hoje a ir além da escola"
          maxWidth="500px"
        />

        <EmptySpace
          desktop={{ margin: 200, isLineVisible: false }}
          mobile={{ margin: 100, isLineVisible: false }}
        />

        <OpenCoursesSection
          header="Inscrições abertas"
          description={[
            "Para todas as informações de cada curso, incluindo datas e canditaturas, clica nas páginas dos cursos"
          ]}
        />

        <EmptySpace
          desktop={{ margin: 200, isLineVisible: false }}
          mobile={{ margin: 100, isLineVisible: false }}
        />

        <ClosedCoursesSection
          images={courseThumbnails}
          allCoursesData={allCoursesJson}
          pathsToCourses={pathsToCourses}
        />

        <EmptySpace
          desktop={{ margin: 140, isLineVisible: false }}
          mobile={{ margin: 142, isLineVisible: true }}
        />
      </Spacing>

      <DesktopNewsletter />

      <Spacing>
        <EmptySpace
          desktop={{ margin: 160, isLineVisible: true }}
          mobile={{ isHidden: true }}
        />

        <Sponsors />

        <EmptySpace
          desktop={{ margin: 120, isLineVisible: false }}
          mobile={{ margin: 80, isLineVisible: false }}
        />
      </Spacing>
    </Layout>
  );
};

export default Courses;
