import 'react-aspect-ratio-fence/css/style.css';
import React from 'react';
import styles from './open-courses.module.scss';
import PropTypes from 'prop-types';
import { navigateTo } from "gatsby";
import InfoCourseCardFluid from '@components/ui/course-info-card-fluid';
import {
  isCourseOpenForSignUp,
  getImageNodeByName,
  getPathToCoursePage,
  getEndDateLabel,
} from '@utilities/course-data-helpers';
import { useCoursesMetadata } from '@queries/use-courses-metadata';

const renderHeader = (header, description) => (
  <div className={styles.description}>
    <section className={styles.heading}>
      <h2>{header}</h2>
    </section>
    <section className={styles.details}>
      {description.map(text => (
        <p className={styles.paragraph} key={String(text).substring(1, 20)}>
          {text}
        </p>
      ))}
    </section>
  </div>
);

const OpenCourses = (props) => {
  const { courseThumbnails, allCoursesJson, pathsToCourses } = useCoursesMetadata();
  const coursesWithClosedSignUp = allCoursesJson.edges.filter(isCourseOpenForSignUp);

  let coursesToDisplay = [...coursesWithClosedSignUp];
  if (props.onlyFeatured) {
    coursesToDisplay = [...coursesWithClosedSignUp.filter(course => course.node.general.featured)];
  }

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(363px, 1fr))`,
    gridColumnGap: '29px',
    gridRowGap: '29px',
  };

  return (
    <div className={styles.container}>
      {props.limit > 0 && renderHeader(props.header, props.description)}
            
      <div style={{ ...containerStyle }}>
        {
          [...coursesToDisplay].slice(0, props.limit).map(course => {
            const { id, general, description } = course.node;
            const handleClick = () => navigateTo(getPathToCoursePage(course, pathsToCourses)); 

            return (
              <div key={id}>
                <InfoCourseCardFluid
                  onClick={handleClick}
                  endDate={getEndDateLabel(general.applications.endDate)}
                  courseName={general.courseName}
                  path={getPathToCoursePage(course, pathsToCourses)}
                  scholarity={description.scholarity}
                  fluid={getImageNodeByName(general.thumbnail, courseThumbnails).childImageSharp.fluid}
                  desktop={{
                    maxWidth: 540,
                    fontSize: { courseName: 40, scholarity: 15 },
                    margin: 40,
                  }}
                  mobile={{
                    maxWidth: 600,
                    fontSize: { courseName: 20, scholarity: 12 },
                    margin: 40,
                  }}
                />
              </div>
            );
          })
        }
      </div>

    </div>
  );
};

export default OpenCourses;

OpenCourses.defaultProps = {
  limit: 9000,
  onlyFeatured: false,
  header: '',
  description: [],
};

OpenCourses.propTypes = {
  limit: PropTypes.number,
  onlyFeatured: PropTypes.bool,
  header: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
};
